body {
  background: url("./images/bg-1.png") no-repeat;
  background-color: #070709;
  color: white;
}

.title {
  color: white;
  margin-bottom: 1 rem;
}

.box {
  margin-top: 50px;
}

.panel {
  margin-top: 2em;
}

.panel-heading {
  xbackground-image: linear-gradient(to left,#38052F, #070709);
  background-color: black;
  color: white;
}

.address-box {
  padding: 3em;
}

.box .title {
  color: #333;
}

.navbar {
  background-color: black;
}

.navbar .navbar-item {
  color: white;
}

.connect-wallet span {
  color: #E10859;
}

.connect-wallet:hover {
  background-color: #e1e1e1 !important;
}

.container.main-content {
  max-width: 1000px !important;
}

.faucet-hero-body {
  margin-top: 100px;
}

.withdraw-error {
    color: #bbb !important;
    padding: 0 4%;
}

.withdraw-success {
  color: green;
}
.withdraw-error {
    word-break: break-all;
}

.panel-block p {
    word-break: break-all;
}
.popup-overlay {
    display: none !important;
}

div#navbarMenu {
    display: block !important;
}
